import { createAppAsyncThunk } from '@/store/createAppThunk.ts'
import { addConversation } from '@/store/conversations/conversationsSlice.ts'
import { DocumentAnalysisResponseDto } from '@/modules/document-analysis/schemas.ts'
import { ConversationEntity } from '@/modules/conversation/domain/entities/ConversationEntity.ts'
import { DpaExamples } from '@/components/DpaOrPrivacyPolicyAnalyzer.tsx'
import { setExampleFileName } from '@/modules/document-analysis/documentAnalysisSlice.ts'
import { setToasterMessage } from '@/store/toasterSlice.ts'
import { ERROR_MESSAGES } from '@/utils/errorMessages.ts'
import { dpaMapper } from '@/modules/document-analysis/mappers/dpaMapper.ts'

export const fetchExampleDpaUseCase = createAppAsyncThunk<ConversationEntity, { example: DpaExamples }>(
  'documentAnalysis/fetchExampleDpaUseCase',
  async ({ example }, { dispatch, extra: { documentAnalysisGateway }, rejectWithValue }) => {
    try {
      const mapper = dpaMapper()
      dispatch(setExampleFileName(example))
      const response: DocumentAnalysisResponseDto = await documentAnalysisGateway.fetchExampleDpa({
        example,
      })
      const newConv = mapper.toEntity(response)
      dispatch(addConversation(newConv))
      return newConv
    } catch (error) {
      dispatch(
        setToasterMessage({
          toasterMessage: ERROR_MESSAGES.GENERIC,
          toasterType: 'generic',
        }),
      )
      return rejectWithValue(ERROR_MESSAGES.GENERIC)
    }
  },
)
