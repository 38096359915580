import { ReactNode, RefObject } from 'react'
import { ConversationOptionsBarContainer } from '@/components/ConversationOptionsBarContainer.tsx'
import { MessagesListContainer } from '@/components/MessagesListContainer.tsx'
import { useMessagesListHook } from '@/components/hooks/use-MessagesList.hook.tsx'
import { cn } from '@/lib/utils.ts'

type ConversationListProps = {
  children: ReactNode
  inputHeight: number
  containerRef: RefObject<HTMLDivElement>
}

export function ConversationList({ children, inputHeight, containerRef }: Readonly<ConversationListProps>) {
  const { messages } = useMessagesListHook()

  return (
    <div
      ref={containerRef}
      className={cn(
        `relative w-full self-start overflow-y-auto p-4 pb-6 lg:self-center lg:px-10 xl:pb-[5rem] xl:pt-10`,
      )}
      style={{ height: `calc(100vh - ${inputHeight}px)` }}
    >
      <div className={cn(messages.length && 'flex flex-col gap-5')}>
        <ConversationOptionsBarContainer />
        <MessagesListContainer />
      </div>
      {children}
    </div>
  )
}
