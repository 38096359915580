import { FileArchive } from 'lucide-react'
import ProgressBar from '@/pages/ProgressBar.tsx'
import { AnalyseStatus } from '@/modules/document-analysis/documentAnalysisSlice.ts'
import { FileStateDpa } from '@/components/DpaOrPrivacyPolicyAnalyzer.tsx'

function DpaFormButtonProgress(
  props: Readonly<{
    fileStateDpas: FileStateDpa[]
    exampleFileName: string
    analyseStatus: AnalyseStatus.IN_PROGRESS | AnalyseStatus.COMPLETE
  }>,
) {
  return (
    <div className="flex w-full flex-col items-center gap-2 md:flex-row">
      <div className="flex w-1/3 items-center gap-2">
        <FileArchive className="h-4 w-4" />
        <div className="max-w-[15ch] truncate">
          {props.fileStateDpas[0]?.file?.unwrap().name ?? props.exampleFileName}
        </div>
      </div>
      <div className="flex w-2/3 flex-col items-center">
        <ProgressBar done={props.analyseStatus === AnalyseStatus.COMPLETE} />
        <div className="text-xs text-gray-500">Analyse du DPA en cours...</div>
      </div>
    </div>
  )
}

export default DpaFormButtonProgress
