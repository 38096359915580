import { http, HttpResponse } from 'msw'
import dpaResponse from './dpaResponse.json'
import privacyPolicyResponse from './privacyPolicyResponse.json'
import listMessagesResponse from './listMessagesResponse.json'

export const handlers = [
  http.post(`${import.meta.env.VITE_OLYMPE_GPT_API_URL}/v1/api/conversations/dpa`, async () => {
    return HttpResponse.json(dpaResponse)
  }),

  http.get(`${import.meta.env.VITE_OLYMPE_GPT_API_URL}/v1/api/conversations/dpa/example`, async () => {
    return HttpResponse.json(dpaResponse)
  }),

  http.post(`${import.meta.env.VITE_OLYMPE_GPT_API_URL}/v1/api/conversations/privacy-policy`, async () => {
    return HttpResponse.json(privacyPolicyResponse)
  }),

  http.get(`${import.meta.env.VITE_OLYMPE_GPT_API_URL}/v1/api/conversations/12345/messages`, async () => {
    return HttpResponse.json(listMessagesResponse)
  }),
]
