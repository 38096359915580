import { createAppAsyncThunk } from '@/store/createAppThunk.ts'
import { ConversationEntity } from '@/modules/conversation/domain/entities/ConversationEntity.ts'
import { addConversation } from '@/store/conversations/conversationsSlice.ts'
import { setToasterMessage } from '@/store/toasterSlice.ts'
import { ERROR_MESSAGES } from '@/utils/errorMessages.ts'
import { LinkEntity } from '@/modules/document-analysis/domain/entities/LinkEntity.ts'
import { privacyPolicyMapper } from '@/modules/document-analysis/mappers/privacyPolicyMapper.ts'

export const analyzePrivacyPolicyUseCase = createAppAsyncThunk<ConversationEntity, { link: LinkEntity }>(
  'documentAnalysis/analyzePrivacyPolicyUseCase',
  async ({ link }, { dispatch, extra: { documentAnalysisGateway }, rejectWithValue }) => {
    try {
      const mapper = privacyPolicyMapper()
      const response = await documentAnalysisGateway.analyzePrivacyPolicy({ link })
      const newConv = mapper.toEntity(response)
      dispatch(addConversation(newConv))
      return newConv
    } catch (error) {
      dispatch(
        setToasterMessage({
          toasterMessage: ERROR_MESSAGES.GENERIC,
          toasterType: 'generic',
        }),
      )
      return rejectWithValue(ERROR_MESSAGES.GENERIC)
    }
  },
)
