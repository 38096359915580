import { OlympeError } from '@/modules/shared/OlympeError.ts'
import { ERROR_MESSAGES } from '@/utils/errorMessages.ts'

export class LinkEntity {
  public url: URL

  private constructor(url: URL) {
    this.url = url
  }

  public static create(url: string): LinkEntity | OlympeError {
    const formattedUrl = url.trim().replace(/\s/g, '').toLowerCase()
    const parsedUrl = this.parseUrl(formattedUrl)
    return parsedUrl instanceof URL ? new LinkEntity(parsedUrl) : parsedUrl
  }

  public unwrap(): URL {
    return this.url
  }

  private static parseUrl(url: string): URL | OlympeError {
    try {
      if (this.hasMultipleProtocols(url) || this.hasInvalidScheme(url) || this.hasInvalidFormat(url)) {
        return this.createError()
      }

      const formattedUrl = this.ensureHttps(url)
      const parsedUrl = new URL(formattedUrl)

      return this.isValidProtocol(parsedUrl) ? parsedUrl : this.createError()
    } catch {
      return this.createError()
    }
  }

  private static hasMultipleProtocols(url: string): boolean {
    return url.split('://').length - 1 > 1
  }

  private static hasInvalidScheme(url: string): boolean {
    return url.includes('://') && !/^https?:\/\//i.test(url)
  }

  private static hasInvalidFormat(url: string): boolean {
    return (
      !/.+\..+/.test(url) ||
      /^(?!\.)[A-Za-z0-9.-]+(?<!\.)\.\.+[A-Za-z0-9.-]+$/.test(url) ||
      /[!<>]/.test(url) ||
      /^[^/]*_/.test(url) ||
      /^-/.test(url) ||
      url.length >= 253 ||
      /\.(pdf|docx?|xlsx?|pptx?|txt|csv|jpg|jpeg|png|gif|svg)(?:\?|$)/i.test(url)
    )
  }

  private static ensureHttps(url: string): string {
    return /^https?:\/\//i.test(url) ? url : `https://${url}`
  }

  private static isValidProtocol(parsedUrl: URL): boolean {
    return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:'
  }

  private static createError(): OlympeError {
    return new OlympeError(ERROR_MESSAGES.INVALID_URL)
  }
}
