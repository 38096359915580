import { TheIconSuccess } from '@/components/icons/TheIconSuccess.tsx'
import { Conversation } from '@/types/conversation'
import { Link } from 'react-router-dom'
import { ConversationType } from '@/modules/conversation/domain/entities/ConversationEntity.ts'
import { cn } from '@/lib/utils.ts'

export function MessageSystemForDPA({ conversation }: Readonly<{ conversation: Conversation }>) {
  const MESSAGE_ITEM_ICON_WIDTH = 38
  const MESSAGE_ITEM_GAP_BETWEEN_ICON_AND_TEXT = 16
  const TOTAL = MESSAGE_ITEM_ICON_WIDTH + MESSAGE_ITEM_GAP_BETWEEN_ICON_AND_TEXT
  if (conversation?.type !== ConversationType.ANALYZE) return null
  return (
    <div
      className={cn(
        `flex w-full max-w-[600px] flex-col justify-between gap-4 rounded-lg bg-brightGray p-3 lg:ml-[${TOTAL}px] lg:flex-row`,
      )}
    >
      {conversation.currentFilesInfos[0] && (
        <div className="flex w-auto items-center gap-2 rounded-md bg-[rgba(193,193,193,0.15)] p-4 lg:w-1/2">
          <TheIconSuccess className={'w-6 fill-primary'} />
          <span
            className={
              'block w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm text-electra hover:text-mintGreen hover:underline'
            }
          >
            {conversation.currentFilesInfos[0]?.title}
          </span>
        </div>
      )}
      <p className={'w-full text-sm lg:w-1/2'}>
        {
          "Ce tableau est une synthèse automatiquement générée par Olympe, qui vous permet d'analyser plus rapidement votre DPA. Si vous y trouvez des erreurs ou si vous souhaitez des améliorations,"
        }{' '}
        <Link
          to={import.meta.env.VITE_OLYMPE_CONTACT_URL}
          target="_blank"
          className="text-electra hover:text-mintGreen hover:underline"
        >
          faites-le nous savoir.
        </Link>
      </p>
    </div>
  )
}
