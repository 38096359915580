import { LegalWatchArticleEntity } from '@/modules/conversation/domain/entities/LegalWatchArticleEntity.ts.ts'

export enum ConversationType {
  MESSAGE_ONLY = 'MESSAGE_ONLY',
  SINGLE_FILE = 'SINGLE_FILE',
  FILE_COMPARISON = 'FILE_COMPARISON',
  ARTICLE = 'ARTICLE',
  ANALYZE = 'ANALYZE',
}
export type MessageCurrentFilesInfos = { title: string; uploadStatus: 'UPLOADED' | 'ERROR' | 'IN_PROGRESS' }[]
export class ConversationEntity {
  public id: string
  public createdAt: string
  public type: ConversationType
  public name: string
  public currentFilesInfos: MessageCurrentFilesInfos
  public articleId?: LegalWatchArticleEntity['id']
  public snapshotId?: string
  public privacyPolicyUrl?: string
  constructor(
    id: string,
    createdAt: string,
    type: ConversationType,
    name: string,
    currentFilesInfos: MessageCurrentFilesInfos,
    articleId?: LegalWatchArticleEntity['id'],
    snapshotId?: string,
    privacyPolicyUrl?: string,
  ) {
    this.id = id
    this.createdAt = createdAt
    this.type = type
    this.name = name
    this.currentFilesInfos = currentFilesInfos
    this.articleId = articleId
    this.snapshotId = snapshotId
    this.privacyPolicyUrl = privacyPolicyUrl
  }
}
